@import "~antd/dist/antd.css";
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App {
  text-align: center;
  background-color: #313336;
  width: 100%;
  height: 100vh;
}
.login {
  width: 40%;
  height: auto;
  padding-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7vh;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
  border-radius: 4px;
  z-index: 99;
  margin: auto;
  position: relative;
  width: 80%;
  height: 100%;
  padding: 17px 10px;
}
.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  background-color: #18181a;
  width: 20%;
  position: relative;
  border-radius: 6px;
  z-index: 1;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.281);
  font-size: 20px;
  font-weight: 500;
  color: #ddd6d6;
}
.right-side:hover {
  width: 23%;
  color: #ffffff;
  font-weight: 600;
}
@media screen and (max-width: 1100px) {
  .login {
    width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .login {
    width: 70%;
  }
}
@media screen and (max-width: 550px) {
  .login {
    width: 95%;
  }
}

/* .right-side.right {
  right: 50em;
  align-items: flex-end;
}
.right-side.right:hover {
  right: 50em;
}
.right-side.left {
  right: 0%;
  align-items: flex-end;
}
.right-side.left:hover {
  right: 0%;
  align-items: flex-end;
}
.right-side.right 
.right-side.left .text {
  font-size: 21px;
  font-weight: 500;
  color: #fff;
} */
