.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  font-size: 26px;
  color: #686262;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.content {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}
.image {
  width: 16em;
  margin-bottom: 2em;
}
img {
  width: 100%;
  height: 100%;
}
